<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="客户名称">
            <a-input
              v-decorator="['name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="资质状态">
            <a-select
              v-decorator="['status']"
              allow-clear
            >
              <a-select-option key="not_submit" value="not_submit">
                未提交
              </a-select-option>
              <a-select-option key="checking" value="checking">
                待审核
              </a-select-option>
              <a-select-option key="not_pass" value="not_pass">
                未通过
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" v-if="isShowSelectSaleOption">
          <a-form-item label="责任销售">
            <a-select
              v-decorator="['sale_id']"
              :loading="loadingSaleOption"
              allow-clear
            >
              <a-select-option
                v-for="sale in salesOptions"
                :key="sale.id"
                :value="sale.id"
              >
                {{ sale.full_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { findSaleOptions } from '@/api/user'
export default {
  name: 'SearchRegisteredAgent',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'registered_agents_search' }),
      salesOptions: [],
      loadingSaleOption: true
    }
  },
  created() {
    if (this.isShowSelectSaleOption) {
      this.fetchAllSaleOptions()
    }
  },
  computed: {
    isShowSelectSaleOption() {
      return this.$store.getters.userRole !== 'sale' && this.$store.getters.userRole !== 'sale_assistant'
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    },
    // 加载销售选项
    fetchAllSaleOptions() {
      findSaleOptions().then((res) => {
        if (res.code === 0) {
          this.salesOptions = res.data
          this.loadingSaleOption = false
        }
      })
    }
  }
}
</script>
