<template>
  <div>
    <search-contracting-parties @submit="submitSearch" />

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <span slot="name" slot-scope="name, record">
        <a-space>
          <a @click="showDetail(record)">{{ name }}</a>
        </a-space>
      </span>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="switchUser(record)">登录</a>
        </a-space>
      </span>
    </a-table>
    <!-- 分页 -->
    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import SearchContractingParties from '@/views/registered_agents/Search'
import Pagination from '@/components/Pagination'
import { switchUser } from '@/api/switch_user'
import { getOriginalUserUid } from '@/utils/token'
import { findRegisteredAgents } from '@/api/registered_agent'
export default {
  name: 'RegisteredAgent',
  data() {
    return {
      data: [],
      isShowNewModal: false,
      isShowEditModal: false,
      loading: false,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  components: {
    SearchContractingParties,
    Pagination
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '客户名称',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          width: 200
        },
        {
          title: '企业资质状态',
          dataIndex: 'status',
          width: 200
        },
        {
          title: '销售',
          dataIndex: 'sale_full_name',
          width: 200
        },
        {
          title: '账号注册时间',
          dataIndex: 'created_at',
          width: 200
        },
        {
          title: '资质提交时间',
          dataIndex: 'last_submitted_at',
          width: 200
        },
        {
          title: '审核时间',
          dataIndex: 'last_checked_at',
          width: 200
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
          width: 100,
          fixed: 'right'
        }
      ]
    }
  },
  methods: {
    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    showDetail(record) {
      this.$router.push({ name: 'registered_agent_info', params: { id: record.id }})
    },

    submitSearch(search) {
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    switchUser(record) {
      switchUser({ registered_agent_id: record.id, original_user_uid: getOriginalUserUid() }).then((res) => {
        if (res.code === 0) {
          location.href = res.data.redirect_url
        }
      })
    },

    fetchData() {
      this.loading = true
      findRegisteredAgents(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
